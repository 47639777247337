"use client"

import useHttp from "@/hooks/useHttp";
import { Toaster } from "react-hot-toast";
import Pusher from 'pusher-js';
import moment from 'moment-timezone';
import { enableCache } from "@iconify/react";
const { createContext, useState, useEffect, useMemo } = require("react");

const AuthContext = createContext(null);

export default function AuthContextProvider({ children }) {
    enableCache('all');
    moment.tz.setDefault('America/Sao_Paulo');
    const { get } = useHttp();

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(null);

    const getClient = async () => {
        if (user != null) {
            return;
        }
        setLoading(true);
        let response = await get(`/api/clients/me`, {}, false);

        if (response?.user) {
            setIsAuth(true);
            setUser(response.user);
            setLoading(false);
        } else {
            setIsAuth(false);
            setUser(null);
            setLoading(false);
        }

        return response;
    };




    useEffect(() => {
        getClient();

    }, []);

    const contextValue = useMemo(() => ({
        user,
        isAuth,
        refresh: getClient,
        loading,
        setUser

    }), [user, isAuth, loading]);

    return <AuthContext.Provider value={contextValue}><div><Toaster /></div>{children}</AuthContext.Provider>
}

export { AuthContext };